'use client';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { FC, PropsWithChildren } from 'react';

// Create an Emotion cache instance
const cache = createCache({ key: 'css' });
cache.compat = true;

export const StylingCache: FC<PropsWithChildren> = ({ children }) => {
  return <CacheProvider value={cache}>{children}</CacheProvider>;
};
