'use client';
import { Global, css } from '@emotion/react';
import { GlobalStyles as BaseStyles } from 'twin.macro';

export const GlobalStyles = () => {
  return (
    <>
      <BaseStyles />
      <Global styles={css``} />
    </>
  );
};
